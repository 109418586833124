import React from 'react'
import Layout from '../../components/Layout'
//import Helmet from "react-helmet"
//import { withPrefix } from "gatsby"



//import PropTypes from 'prop-types'
//import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export default () => (
  <Layout pageMetadata={{title:'The Immunity Meal Plan', description:'page description'}}>
  <section className="section section--gradient">
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
        <div style={{marginLeft: "25px"}}><a href="/immunity/">The Whole Foods Immunity Theory</a><a href="# " style={{color: "black"}}> > Appendix B: References</a></div>
          <div className="section">
          <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          References
          </h2>

          <div align="center">

            <iframe title="References" width="830" height="1170" align="center" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTn8Csl0yRpn5Uc-CmxEDFHpGNouASsDdqncUBIgTbApQiWh4KNukUZEmBi1NF4gspEkbWCWYT6-n0M/pubhtml?widget=true&amp;headers=false"></iframe>
          </div>
          </div>

        </div>

      </div>
    </div>
</section>
  </Layout>
)
